import { BackButton } from "../components/UI/BackButton";
import React from "react";
import territorio from "../assets/territorio1.jpg";
import { useTranslation } from "react-i18next";
export const ComuneETerritorio = () => {
  const { t } = useTranslation();
  return (
    <section
      className="bg-mazaraPrimary text-left w-full"
      style={{ height: "100vh" }}
    >
      <div
        className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}
      >
        <div className={"flex flex-wrap items-center"}>
          <span className={"text-white font-semibold text-[18px] ml-4"}>
            {t("comuneETerritorioTitle")}
          </span>
        </div>
      </div>
      <img src={territorio} className={"mb-4"} />
      <p className={"text-white font-bold text-[20px] mx-6 my-2"}>
        {t("comuneETerritorioSubTitle1")}
      </p>
      <p className={"text-white font-semibold text-[16px] mx-6"}>
        {t("comuneETerritorioText1")}
      </p>
      <p className={"text-white font-bold text-[20px] mx-6 my-2"}>
        {t("comuneETerritorioSubTitle2")}
      </p>
      <p className={"text-white font-semibold text-[16px] mx-6 pb-5"}>
        {t("comuneETerritorioText2")}
      </p>
      <div
        style={{
          position: "absolute",
          bottom: "2%",
          left: "2%",
          width: "100%",
        }}
      >
        <BackButton sfondo={"false"} width={"3rem"} height={"3rem"} />
      </div>
    </section>
  );
};
